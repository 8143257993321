import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Contact from '../components/contact/Contact'
import Layout from "../components/Layout"
import SEO from "../components/SEO"

const SecondPage = ({ location }) => {
  const data = useStaticQuery(graphql `
    query {
      etienne: file(relativePath: { eq:"images/etienne.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 982, maxHeight: 983) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      alex: file(relativePath: { eq:"images/alex.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1092, maxHeight: 981) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      whatsapp: file(relativePath: { eq:"images/whatsapp.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 512, maxHeight: 512) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      checkmark: file(relativePath: { eq:"images/checkmark.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 177, maxHeight: 174) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      ODBlack: file(relativePath: { eq:"images/ODBlack.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 1491, maxHeight: 842) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `)
  const img = (name) => data[name].childImageSharp.fluid
  return (
    <Layout>
      <SEO title="Contact"/>
      <Contact
        etienne={img('etienne')}
        alex={img('alex')}
        whatsapp = {img('whatsapp')}
        checkmark = {img('checkmark')}
        logo = {img('ODBlack')}
        location={location}
      />
    </Layout>
  )
}

export default SecondPage
