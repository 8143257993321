import React from "react"
import "../../css/contact/contact.scss"
import Header from '../Header'
import Img from "gatsby-image"

const Contact = ({ etienne, alex, whatsapp, checkmark, logo }) => {
  if (typeof window !== `undefined`) {
    const header = document.getElementById("header")
    const logoText = document.getElementById("logoText")
    if (header !== null && logoText !== null) {
      header.style.background = "white";
      header.style.opacity = "1";
      logoText.style.color = "black"
    }
    window.onscroll = function() {scrollFunction()};
  }

  function scrollFunction() {
    const header = document.getElementById("header")
    const logoText = document.getElementById("logoText")
    if (window.location.href.indexOf("contact") > -1) {
      if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
      header.style.background = "white";
      header.style.opacity = "1";
      logoText.style.color = "black"
      } 
    } 
  }


  return (
  <div className="contactRoot">
      <Header 
        logo={logo}
        origin={"contact"}
      />
    <div className="contact">
      <div className="contactLine"/>
      <h1 className="contactText">
        Contact
      </h1>
    </div>
    <div className="mainColumn">
      <h2 className="getInTouchText">
        Get in touch<br className="onlyMobileBR"/> with us.
      </h2>
      <div className="directContactRow">
        <div className="directContactColumn">
          <Img fluid={etienne} className="directContactImage" imgStyle={{ objectFit: 'contain' }}/>
          <h2 className="directContactTitle">
            Etienne Dusseault
          </h2>
          <h3 className="directContactDescription">
            Co-founder<br/>
            Tech Team Lead
          </h3>
          <a className="directContactButtonWrapper" target="_blank" rel="noreferrer" href="https://wa.me/6287774071774?text=I'm%20Etienne%20from%20OrderDrive.%20Let's%20chat%20about%20your%20business!">
            <button className="directContactButton">
              <span>Contact</span> <Img fluid={whatsapp} className="whatsappIcon" imgStyle={{ objectFit: 'contain' }}/>
            </button>
          </a>
        </div>
        <div className="directContactColumn">
          <Img fluid={alex} className="directContactImage" imgStyle={{ objectFit: 'contain' }}/>
          <h2 className="directContactTitle">
            Alexandre Lavoie
          </h2>
          <h3 className="directContactDescription">
            Co-founder<br/>
            Business Team Lead
          </h3>
          <a className="directContactButtonWrapper" target="_blank" rel="noreferrer" href="https://wa.me/6585589244?text=I'm%20Alexandre%20from%20OrderDrive.%20Let's%20chat%20about%20your%20business!">
            <button className="directContactButton">
              <span>Contact</span> <Img fluid={whatsapp} className="whatsappIcon" imgStyle={{ objectFit: 'contain' }}/>
            </button>
          </a>
        </div>
      </div>
    </div>
  </div>
)
}

export default Contact