import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import "../css/header.scss"
import Img from "gatsby-image"

const Header = ({ logo }) => {
  return (
  <header className='header' id='header'>
      <Link to="/" style={{ textDecoration: 'none' }} state={{home: true}}>
        <div className='logoTextWrapper'>
          <Img fluid={logo} id='logo' className="headerLogo" imgStyle={{ objectFit: 'contain' }}/>
          <h1 className='logoText' id="logoText">
            ORDER DRIVE
          </h1>
        </div>
      </Link>
  </header>
)
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
